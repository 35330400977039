import React, { useEffect } from 'react';
import LandingPage from './LandingPage';
import { setFavicon } from './Logo';

function App() {
  useEffect(() => {
    setFavicon();
  }, []);

  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;