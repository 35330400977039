import React from 'react';

const christmasColors = {
  primary: '#C41E3A',  // A christmassy red
  secondary: '#228B22' // A festive green
};

const AnimatedSilvercrestLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" width="200" height="100">
    <defs>
      <linearGradient id="silver-gradient-animated" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" style={{stopColor: '#C0C0C0', stopOpacity: 1}} />
        <stop offset="100%" style={{stopColor: '#E8E8E8', stopOpacity: 1}} />
      </linearGradient>
    </defs>
    <path d="M20,50 Q50,20 80,50 T140,50" fill="none" stroke="url(#silver-gradient-animated)" strokeWidth="10">
      <animate attributeName="stroke-dasharray" from="0 200" to="200 200" dur="2s" fill="freeze" />
    </path>
    <circle cx="170" cy="50" r="10" fill={christmasColors.primary}>
      <animate attributeName="r" values="0;10;8;10" dur="1s" repeatCount="indefinite" />
    </circle>
    <text x="100" y="85" fontFamily="Arial" fontSize="14" fill={christmasColors.secondary} textAnchor="middle">
      <tspan>ILLUMINATIONS</tspan>
      <animate attributeName="opacity" values="0;1" dur="1s" fill="freeze" begin="1.5s" />
    </text>
  </svg>
);

export const generateFavicon = () => {
  const svgString = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="32" height="32">
      <path d="M10,50 Q25,20 40,50 T70,50" fill="none" stroke="${christmasColors.secondary}" stroke-width="10" />
      <circle cx="85" cy="50" r="10" fill="${christmasColors.primary}" />
    </svg>
  `;
  return `data:image/svg+xml;base64,${btoa(svgString)}`;
};

export const setFavicon = () => {
  const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
  favicon.type = 'image/svg+xml';
  favicon.rel = 'shortcut icon';
  favicon.href = generateFavicon();
  document.getElementsByTagName('head')[0].appendChild(favicon);
};

export default AnimatedSilvercrestLogo;